import { useRef } from "react"
import NextImage from "next/image"
import NextLink from "next/link"
import PropTypes from "prop-types"
import classNames from "classnames"
import { getUrlAndType } from "@lib/utils"
import useIsAboveTheFold from "@lib/custom-hooks/aboveTheFold"

// ---------------------------------------------------------

import { image_container, image_hover, has_caption } from "./styles.module.scss"

// ---------------------------------------------------------

const normalizeSrc = (src) => {
  if (!src) return

  return src[0] === "/" ? src.slice(1) : src.replace("https://images.ctfassets.net", "")
}

const checkImgixViability = (src) => {
  return src.includes("https://images.ctfassets.net")
}

const imgixLoader = ({ src, width, quality }) => {
  const url = new URL("https://amperity.imgix.net" + normalizeSrc(src))

  const params = url.searchParams

  params.set("auto", params.getAll("auto").join(",") || "format,compress")
  params.set("fit", params.get("fit") || "max")
  params.set("w", params.get("w") || width.toString())
  if (quality) {
    params.set("q", quality.toString())
  }
  return url.href
}

// ---------------------------------------------------------

const Image = (props) => {
  const ref = useRef(null)
  const isAboveTheFold = useIsAboveTheFold(ref)
  const { isUnoptimized, ...filteredProps } = props

  if (!props.src) return
  const { isExternal, url } = getUrlAndType(props.link)
  const handleAsImgix = checkImgixViability(props.src)

  const classes = classNames(image_container, {
    [has_caption]: props.caption,
    [props.className]: props.className
  })

  const imageComponent = (
    <NextImage
      loader={handleAsImgix ? imgixLoader : null}
      {...filteredProps}
      src={props.src}
      loading={isAboveTheFold ? "eager" : "lazy"}
      unoptimized={isUnoptimized}
    />
  )

  if (props.link) {
    return (
      <div className={classes}>
        <NextLink
          href={url}
          className={image_hover}
          target={isExternal ? "_blank" : "_self"}
          rel={isExternal ? "noreferrer" : "none"}
        >
          {imageComponent}
        </NextLink>
      </div>
    )
  }

  return (
    <div className={classes} ref={ref}>
      {imageComponent}
      {props.caption && <span>{props.caption}</span>}
    </div>
  )
}

Image.defaultProps = {
  isUnoptimized: false
}

Image.propTypes = {
  /**
   * Specifies the image alt attribute.
   */
  alt: PropTypes.string,

  /**
   * Specifies a caption under the video
   */
  caption: PropTypes.string,

  /**
   * Specifies the height of the image in pixels
   */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * Specifies the URL that image links to.
   */
  link: PropTypes.string,

  /**
   * Specifies if the image should be high priority and preload.
   */
  priority: PropTypes.bool,

  /**
   * Specifies the image src as a string
   */
  src: PropTypes.string.isRequired,

  /**
   *  Specifies if we should use the unoptimized full res image
   */
  isUnoptimized: PropTypes.bool,

  /**
   * Object to specify the styles for the image, such as objectFit, objectPosition etc.
   */
  style: PropTypes.object,

  /**
   * Specifies the width of the image in pixels
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

Image.defaultProps = {}

export default Image
