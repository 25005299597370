import { utcToZonedTime, format } from "date-fns-tz"
import { ExperienceMapper } from "@ninetailed/experience.js-utils"

export const UTCisMidnight = (UTCstring) => {
  // Checks contentful time input to be 00:00 or non-existent
  if (!UTCstring) return true
  const UTCTimeString = UTCstring.slice(11, 16)
  return UTCTimeString === "00:00"
}

export const getCurrentDate = () => {
  const currentDate = new Date()
  return currentDate
}

/**
 *
 * @param {*} startDate A date and time in ISO 8601 format (with UTC)
 * @param {*} endDateA date and time in ISO 8601 format (with UTC)
 * @returns A formatted string (e.g., January 1, 2025) with the startDate, endDate, and time zone.
 */
export const getDate = (startDate, endDate) => {
  const { date: formattedStartDate, time: formattedStartTime } = getDateTimeObj(startDate)
  const { date: formattedEndDate, time: formattedEndTime } = getDateTimeObj(endDate)

  const startTimes = UTCisMidnight(startDate) ? "" : `${formattedStartTime} `
  const endTimes = UTCisMidnight(endDate) ? "" : `${formattedEndTime} `

  if (startDate.toString() === endDate?.toString() || !endDate) {
    // No end date (resources), OR same start & end date & time
    return `${formattedStartDate} ${startTimes}`
  } else if (formattedStartDate === formattedEndDate) {
    // Same date, different time
    return `${formattedStartDate} from ${formattedStartTime} to ${formattedEndTime} `
  } else if (formattedStartDate !== !formattedEndDate && startTimes === endTimes) {
    // Different date, same time,
    return `${formattedStartDate} to ${formattedEndDate}`
  } else {
    // Different date, different times
    return `${formattedStartDate}, ${startTimes} to ${formattedEndDate}, ${endTimes}`
  }
}

export const getDateTimeObj = (input) => {
  if (!input) return { date: "", time: "" }

  //Returns the user's local timezone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const localDateTime = utcToZonedTime(input, userTimeZone)
  const date = format(localDateTime, "MMMM d, yyyy")
  const time = format(localDateTime, "h:mmaaa z")
  return { date, time }
}

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

export const pluralize = (str) => {
  const last = str[str.length - 1]
  switch (last) {
    case "s":
      return str
    case "y":
      return `${str.slice(0, -1)}ies`
    default:
      return `${str}s`
  }
}

export const parameterize = (str) => {
  if (!str) return undefined
  return str.toLowerCase().replace(/[^a-zA-Z0-9]/g, "-")
}

export const dehyphenate = (varName) => {
  if (!varName) return undefined
  return varName.replace(/-/g, " ")
}

export const getFileExtension = (file) => {
  return file.split(".").pop()
}

export const parseResourceType = (resourceType) => {
  const downloadTypes = [
    "Case Study",
    "Guide",
    "Infographic",
    "Product Brief",
    "Research and Reports",
    "Solution Brief",
    "Video"
  ]
  return downloadTypes.includes(resourceType) ? "downloads" : parameterize(pluralize(resourceType))
}

export const removeUndefinedAndNull = (obj) => {
  for (let k in obj) {
    if (obj[k] === undefined || !obj[k]) {
      delete obj[k]
    }
  }
  return obj
}

export const transformFlatArrToObj = (inputArray, key, property) => {
  if (!inputArray || inputArray.length < 1) return {}
  return inputArray.reduce((output, current) => {
    output[current[key]] = current[property]
    return output
  }, {})
}

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const getTitleCase = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.replace(word[0], word[0].toUpperCase())
    })
    .join(" ")
}

export const currencyFormatter = (amount) => {
  const convertedAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  }).format(amount)

  return convertedAmount.replace(/\$/, "$ ")
}

export const numberFormatter = (number, sigdig = 3) => {
  return new Intl.NumberFormat("en-US", {
    maximumSignificantDigits: sigdig
  }).format(number)
}

export const convertYoutubeUrlToId = (url) => {
  // Test if URL is an ID (11 char)
  const onlyIdRegExp = /^[\da-zA-Z]{11}$/
  if (!url || onlyIdRegExp.test(url)) return url

  // Test if URL contains ID
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[7].length == 11 ? match[7] : false
}

export const toQuery = (params) => {
  return Object.keys(params)
    .map((key) => key + "=" + encodeURI(params[key]))
    .join("&")
}

export const intersection = (a, b) => {
  return (a || []).filter((x) => (b || []).includes(x))
}

/**
 *
 * @param {string} url
 * @returns {string} url: converted url or path, without the domain if internal
 * @returns {bool} isExternal: whether the url links to outside amperity.com
 */
export const getUrlAndType = (url) => {
  if (!url) return { url: undefined, isExternal: false, showIcon: false }

  // internal amperity url
  if (url.match(/https?:\/\/(www\.)?amperity\.com/)) {
    const path = new URL(url).pathname
    return { url: path, isExternal: false, showIcon: false }
    //assets.ctfassets.net url (Contentful hosted files)
  } else if (url.match(/https?:\/\/assets\.ctfassets\.net/)) {
    return { url: url, isExternal: true, showIcon: false }
    //check if url starts with # for internal scroll
  } else if (url.match(/^#/)) {
    return { url: url, isExternal: false, showIcon: false }
  }
  // http(s) url that's not Amperity
  else if (url.match(/^http/)) {
    return { url: url, isExternal: true, showIcon: true }
    // any other url considered internal and feeds back input url
  } else {
    return { url: url, isExternal: false, showIcon: false }
  }
}

/**
 * @param {string} url An image url
 * @param {string} params Imgix query parameters (optional)
 * @returns If the image is coming from ctfassets then it will replace the url with an imgix link
 *
 * Also adds "?auto=format,compress" to the end of the imgix link. If auto=format is included in the query,
 * images will be served in AVIF format whenever possible while still applying compression. When
 * AVIF is not supported, the image will be served as a WebP. If the WebP format is not supported,
 * images that contain transparency will be served in a PNG8 format (if supported) and all others
 * will be served as JPEG.
 */
export const replaceWithImgix = (url, params) => {
  if (
    typeof url === "string" &&
    url.includes("images.ctfassets.net") &&
    !url.includes(".gif") &&
    !url.includes(".svg")
  ) {
    let str = []
    for (let p in params) {
      // eslint-disable-next-line no-prototype-builtins
      if (params.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]))
      }
    }

    let imgixParams = ""

    if (params) {
      imgixParams =
        "&" +
        Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")
    }

    return url
      .replace("images.ctfassets.net/5vqt5w81x2im", "amperity.imgix.net")
      .concat(`?auto=format,compress${imgixParams}`)
  }
  return url
}

export const truncate = (string, charLimit) => {
  // return the original string if not too long
  if (string.length < charLimit) {
    return string
  }

  // Split the string into an array of words
  const words = string.split(" ")
  let result = ""
  let count = 0

  // Iterate through the words
  for (const word of words) {
    // Add the length of the current word to the character count
    // +1 to account for the spaces after words
    count += word.length + 1

    // either break,
    if (count > charLimit + 1) {
      break
    } else if (count >= charLimit) {
      result += word
    } else {
      result += word + " "
    }
  }

  // Otherwise, return the result string plus an ellipsis
  return result.trim() + "…"
}

export const generateArticleSchema = (blogPostData) => {
  if (!blogPostData || blogPostData.length === 0) {
    return null
  }
  const { slug, title, excerpt, date, featuredImage, authorCollection } = blogPostData

  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `https://amperity.com/blog/${slug}`
    },
    headline: title,
    description: excerpt,
    image: featuredImage.url,
    author: authorCollection.items.map((author) => ({
      "@type": "Person",
      name: author.name,
      url: `https://amperity.com/people/${author.slug}`
    })),
    publisher: {
      "@type": "Organization",
      name: "Amperity",
      logo: {
        "@type": "ImageObject",
        url: "https://images.ctfassets.net/5vqt5w81x2im/41315/5c60cf5fc0b13eed56d19fc4481edbc5/Amperity_HorizLogo_Black.svg"
      }
    },
    datePublished: date
  })
}

export const generateFaqSchema = (faqData) => {
  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqData.map((faq) => ({
      "@type": "Question",
      name: faq.title,
      acceptedAnswer: {
        "@type": "Answer",
        text: faq.answer
      }
    }))
  })
}

export const generateBreadCrumbSchema = (data, slug) => {
  let index = 0
  let breadCrumb = ""

  const pingPong = data.modulesCollection.items.filter((item) => item.__typename === "PingPong")

  const pingPongBreadCrumbs = pingPong[0].text.json.content[0].content
  const pageTitle = pingPong[0].text.json.content[1].content[0].value

  const hyperlinks = pingPongBreadCrumbs.filter((item) => item.nodeType === "hyperlink")
  const filteredText = pingPongBreadCrumbs.filter(
    (item) => item.nodeType === "text" && item.value.trim() !== ">" && item.value.trim().length > 1
  )

  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      ...hyperlinks.map((link) => {
        index++
        breadCrumb = breadCrumb + link.data.uri
        return {
          "@type": "ListItem",
          position: index,
          name: link.content[0].value,
          item: `https://amperity.com${link.data.uri}`
        }
      }),
      ...filteredText.map((text) => {
        index++
        return {
          "@type": "ListItem",
          position: index,
          name: text.value.trim(), // Replace with the actual property name from your hyperlinks object
          item: `https://amperity.com${breadCrumb + slug}` // Replace with the actual property name from your hyperlinks object
        }
      }),
      {
        "@type": "ListItem",
        position: index + 1,
        name: pageTitle
      }
    ]
  })
}

export function parseExperience(experience) {
  return {
    name: experience.ntName,
    type: experience.ntType,
    config: experience.ntConfig,
    ...(experience.ntAudience
      ? {
          audience: {
            id: experience.ntAudience.ntAudienceId,
            name: experience.ntAudience.ntName
          }
        }
      : {}),
    description: experience.description ?? "",
    id: experience.sys.id,
    variants: experience.ntVariantsCollection.items.map((variant) => {
      return {
        template: variant.__typename,
        id: variant.sys.id,
        ...variant
      }
    })
  }
}

export function filterAndMapExperiences(experiences) {
  return experiences
    .map((experience) => parseExperience(experience))
    .filter(ExperienceMapper.isExperienceEntry)
    .map(ExperienceMapper.mapExperience)
}

export function parseAudience(audience) {
  return {
    id: audience.ntAudienceId,
    name: audience.ntName,
    description: audience.ntDescription ?? ""
  }
}

export function mapAudiences(audiences) {
  return audiences.map((audience) => parseAudience(audience))
}

export function hasDarkBackground(backgroundColor) {
  return (
    backgroundColor === "anvil" ||
    backgroundColor === "lagoon" ||
    backgroundColor === "off-black" ||
    backgroundColor === "dusk"
  )
}

// Finding the target node in a content type
export function findNodeByType(nodes, type) {
  return nodes.find((node) => node?.nodeType === type)
}
