import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import dynamic from "next/dynamic"

// ---------------------------------------------------------

import { hasDarkBackground } from "@lib/utils"

const Button = dynamic(() => import("@components/button"), { ssr: false })

// ---------------------------------------------------------

import { alert_bar, alert_bar_label, alert_bar_button } from "./styles.module.scss"

// ---------------------------------------------------------

const AlertBar = (props) => {
  let { alertText, backgroundColor, buttonLabel, buttonLink, className, gradientColor } = props

  // ---------------------------------------------------------

  // Use gradient coloring when field has been populated in contentful
  function getGradientStyle() {
    const defaultLimoncello = "#edff95"
    const gradientStyle = {
      ...(gradientColor && {
        style: {
          background: `linear-gradient(90deg, ${gradientColor} 0%, ${defaultLimoncello} 30%`
        }
      })
    }
    return gradientStyle
  }

  let classes = classNames(
    alert_bar,
    { [className]: className },
    { [`background_${backgroundColor}`]: backgroundColor }
  )

  const buttonTheme =
    backgroundColor && hasDarkBackground(backgroundColor)
      ? "arrow-horizontal-light"
      : "arrow-horizontal-dark"

  let alertTextContent = <span className={alert_bar_label}>{alertText}</span>

  const showButton = (
    <Button
      className={alert_bar_button}
      theme={buttonTheme}
      title={buttonLabel}
      labelAttr={buttonLabel}
      url={buttonLink}
    >
      {buttonLabel}
    </Button>
  )

  return (
    <div className={classes} {...getGradientStyle()} role="alert">
      {alertTextContent}
      {buttonLabel && buttonLink && showButton}
    </div>
  )
}

AlertBar.propTypes = {
  /**
   * Text rendered to the screen inside the alert bar
   */
  alertText: PropTypes.string,

  /**
   * Specifies the alert bar background color, choose 1 of 7 main background colors
   */
  backgroundColor: PropTypes.oneOf([
    "white",
    "cement",
    "anvil",
    "dusk",
    "ice",
    "lagoon",
    "limoncello",
    "off-black",
    "prime-teal"
  ]),

  /**
   * Text rendered to the screen inside the button within the alert bar
   */
  buttonLabel: PropTypes.string,

  /**
   * Specifies where the button within the alert bar links to
   */
  buttonLink: PropTypes.string,

  /**
   * Specifies additional class names
   */
  className: PropTypes.string,

  /**
   * Specifies the gradient color
   */
  gradientColor: PropTypes.string
}

AlertBar.defaultProps = {
  backgroundColor: "limoncello"
}

export default AlertBar
